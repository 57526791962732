/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.support-search__search {
  padding: 0 0 1.25rem; }

.support-search__form {
  margin: auto;
  position: relative;
  width: 100%;
  max-width: 50rem;
  padding: 0 0 3.25rem; }

.support-search__field {
  border: none;
  background-color: #f4f4f4;
  height: 2.8125rem;
  padding-left: 3.875rem;
  padding-right: 1.5rem;
  color: black;
  font-size: 1.125 rem;
  /* flex-basis: 50%; */
  border-radius: 1.40625rem;
  width: 100%;
  max-width: 800px;
  background-image: url("../../images/icons/search-lg.svg");
  background-size: 25px;
  background-position: 20px 50%;
  background-repeat: no-repeat; }

.support-search__button {
  display: none; }

.support-media-banner .support-search__search {
  padding: 0; }

.support-media-banner .support-search__field {
  border-radius: 2.40625rem;
  max-width: 845px;
  height: 3.5625rem; }

.quick-sol-pods {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem; }
  .quick-sol-pods.container {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }

.support-landing .quick-solutions__item {
  margin-bottom: 1.5rem;
  background: url(https://s3.belkin.com/resources/sfcc/support/arrow-icon-qs.png) left center no-repeat;
  padding-left: 2.5rem;
  padding-top: 3px;
  min-height: 25px; }

.support-landing .carousel .section-heading {
  padding: 0 1rem; }

.support-landing .content-tile .tile-content {
  margin: 0 0.9375rem;
  padding: 2.125rem 1.375rem; }

.support-search-link-wrapper {
  padding-top: 1.5rem;
  padding-bottom: 1rem; }

.support-search__search {
  padding: 0.9375rem; }

.support-search__form {
  padding: 0; }

@media (min-width: 769px) {
  .quick-sol-pods {
    padding-top: 4.25rem;
    padding-bottom: 0; }
    .quick-sol-pods.container {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; }
  .support-landing .support-search__form {
    width: 50vw;
    margin: 0; }
  .support-landing .support-search__field {
    width: 50vw; }
  .support-search-form {
    width: 50vw; }
    .support-search-form input.support-search-text {
      width: 50vw; }
  .support-search-link-wrapper {
    padding-top: 0px; }
  .support-media-banner .support-search-link-wrapper {
    padding-top: 7px;
    padding-left: 3.875rem; } }

@media (max-width: 768px) {
  .support-landing .pd-layout .region:not(:last-child) {
    margin-bottom: 47px; } }
